function getUpdatedTitle(title: string, prefix: string, suffix: string) {
  const updatedTitle = `${title.replace(prefix, '')}${suffix}`;
  return updatedTitle.trim();
}

export function getSeoTitle(title = '') {
    const brandString = 'Bernhard Kohl';
    const suffix = ` - ${brandString}`;
    const prefix = `${brandString} -`;

    const trimmedTitle = title.trim();
    if (trimmedTitle.endsWith(suffix.trimStart()) || trimmedTitle.endsWith(brandString)) {
      return title;
    }

    if (trimmedTitle.startsWith(prefix)) {
      return getUpdatedTitle(trimmedTitle, prefix, suffix);
    }

    if (trimmedTitle.startsWith(brandString)) {
      return getUpdatedTitle(trimmedTitle, brandString, suffix);
    }

    return `${trimmedTitle}${suffix}`;
}

const maxDescriptionLength = 155;
export function getSeoDescription(description = '') {
    if (!description) return '';
    const descriptionWithoutHtmlTags = description.replace(/<[^>]*>/g, '');
    const truncatedDescription = descriptionWithoutHtmlTags.slice(0, maxDescriptionLength);
    const lastSentenceEnd = truncatedDescription.lastIndexOf('.');

    return lastSentenceEnd !== -1 ? truncatedDescription.slice(0, lastSentenceEnd + 1) : truncatedDescription;
}

export function getTitleWithManufacturer(title: string, manufacturer: string):string {
  if (title.startsWith(manufacturer) || !manufacturer) {
    return title;
  }

  return `${manufacturer} ${title}`;
}
