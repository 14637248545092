



































import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import AppHeader from '~/components/AppHeader.vue';
import AppFooter from '~/components/Footer/AppFooter.vue';
import SeoMeta from '~/components/Seo/SeoMeta.vue';
import Logobar from '~/components/Logobar.vue';
import Newsletter from '~/components/Newsletter.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import { useStoryblokAsync } from '~/composables/useStoryblok';
import RenderContent from '../cms/helpers/RenderContent.vue';

const DEFAULT_CMS_RELATIONS = 'navigation,Navigation.menuItems,footer,topBar,newsletter,metaTags';

export default defineComponent({
  name: 'CmsPage',
  components: {
    RenderContent,
    AppHeader,
    AppFooter,
    Logobar,
    TopBar,
    Newsletter,
    SeoMeta,
  },
  setup() {
    const route = useRoute();
    const page = computed(() => route.value.params.slug);

    const storyblokData = useStoryblokAsync(page.value, DEFAULT_CMS_RELATIONS);
    return {
      storyblokData,
    };
  },
  methods: {
    isHeaderTransparent() {
      return this.storyblokData?.navigation?.backgroundColor === 'transparent';
    },
  },
});
