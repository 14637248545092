

























import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { Routes } from '~/helpers/routes';

interface IBrands {
  icon: string;
  link: string;
  width: number;
  height: number;
}

export default defineComponent({
  setup() {
    const brands: IBrands[] = [
      {
        icon: 'cube',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=8`,
        width: 200,
        height: 35,
      },
      {
        icon: 'pinarello',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=19`,
        width: 200,
        height: 29,
      },
      {
        icon: 'mondraker',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=17`,
        width: 200,
        height: 27,
      },
      {
        icon: 'cannondale',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=7`,
        width: 200,
        height: 23,
      },
      {
        icon: 'scott',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=21`,
        width: 200,
        height: 31,
      },
      {
        icon: 'bianchi',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=5`,
        width: 200,
        height: 19,
      },
      {
        icon: 'rotwild',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=20`,
        width: 200,
        height: 35,
      },
      {
        icon: 'wilier',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=591`,
        width: 200,
        height: 27,
      },
      {
        icon: 'focus',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=10`,
        width: 200,
        height: 17,
      },
      {
        icon: 'bmc',
        link: `/${Routes.CATEGORY}/alle-rader?manufacturer=6`,
        width: 200,
        height: 35,
      },
    ];
    return {
      addBasePath,
      brands,
    };
  },
  methods: {
    goToOverview(link: string) {
      this.$router.push(link);
    },
  },
});
