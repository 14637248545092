
























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { extractComponents } from '@vue-storefront/storyblok';

interface RenderContent {
  componentName: string
  props?: {}
}

export default defineComponent({
  name: 'RenderContent',
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    hasTopBar: {
      type: Boolean,
      default: false,
    },
    isHeaderTransparent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
      const components = computed((): RenderContent[] => extractComponents(props.content));

      return {
        components,
      };
  },
  methods: {
    hasSpacing(componentName: string): boolean {
      return !(componentName.includes('Header') || componentName.includes('Claim') || componentName.includes('Call'));
    },
    firstHasSpacing(): boolean {
      return this.hasSpacing(this.components[0].componentName);
    },
  },
});
